import React, { useState, createContext, useContext } from "react";
import { CToast, CToastBody, CToaster } from "@coreui/react";
import { ToastContextProps } from "@interfaces/components";
import { AxiosError } from "axios";

const ToastContext = createContext<ToastContextProps>({
  showSuccess: () => {},
  showError: () => {},
  showInfo: () => {},
  showWarning: () => {},
});

const useToast = () => {
  const toast: any = useContext(ToastContext);

  return {
    showSuccess: toast.showSuccess,
    showError: toast.showError,
    showInfo: toast.showInfo,
    showWarning: toast.showWarning,
  };
};

const ToastComponent = ({ children }: any) => {
  const [toasts, setToasts] = useState<any[]>([]);

  const addToast = (color: string, titulo: string, mensaje: string) => {
    setToasts([
      ...toasts,
      {
        position: "top-right",
        autohide: 1500,
        closeButton: true,
        fade: true,
        color: color,
        titulo: titulo,
        mensaje: mensaje,
      },
    ]);
  };

  const toasters = (() => {
    return toasts.reduce((toasters, toast) => {
      toasters[toast.position] = toasters[toast.position] || [];
      toasters[toast.position].push(toast);
      return toasters;
    }, {});
  })();

  const showSuccess = (newMessage: string) => {
    addToast("success", "Exito", newMessage);
  };

  const showError = (newMessage: AxiosError | string) => {
    let errorMessage = "Ha ocurrido un error.";
    if (typeof newMessage === "string") {
      errorMessage = newMessage;
    } else if (typeof newMessage === "object" && newMessage.isAxiosError) {
      if (newMessage?.response?.data?.message) {
        errorMessage = newMessage?.response?.data?.message;
      }
    }
    addToast("danger", "Error", errorMessage);
  };

  const showInfo = (newMessage: string) => {
    addToast("info", "Información", newMessage);
  };

  const showWarning = (newMessage: string) => {
    addToast("warning", "Advertencia", newMessage);
  };

  return (
    <ToastContext.Provider value={{ showSuccess, showError, showInfo, showWarning }}>
      {Object.keys(toasters).map((toasterKey: any) => (
        <CToaster position={toasterKey} key={"toaster" + toasterKey}>
          {toasters[toasterKey].map((toast: any, key: any) => {
            return (
              <CToast key={"toast" + key} show={true} autohide={toast.autohide} fade={toast.fade} color={toast.color}>
                <CToastBody>{toast.mensaje}</CToastBody>
              </CToast>
            );
          })}
        </CToaster>
      ))}

      {children}
    </ToastContext.Provider>
  );
};

export { ToastContext, useToast, ToastComponent };
