import React, { useState, useEffect } from "react";
import { LoginResponse } from "@interfaces/types";
import { AuthContextProps } from "@interfaces/components";

export const AuthContext = React.createContext<AuthContextProps>({
    isAuthenticated: false,
    isLoading: true,
    login: () => {},
    logout: () => {},
});

export const saveSessionData = (values: LoginResponse) => {
    localStorage.setItem("sessionData", JSON.stringify(values));
};

export const getSessionData = () => {
    const sessionData = localStorage.getItem("sessionData");
    return sessionData ? JSON.parse(sessionData) : null;
};

export const removeSessionData = () => {
    localStorage.removeItem("sessionData");
};

export default function Auth({ children }: any) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        checkAuth();
    }, []);

    const checkAuth = () => {
        setIsLoading(true);
        const sessionData = getSessionData();
        if (sessionData) {
            setIsAuthenticated(true);
        }
        setIsLoading(false);
    };

    const login = (sessionData: LoginResponse) => {
        saveSessionData(sessionData);
        setIsAuthenticated(true);
    };

    const logout = () => {
        removeSessionData();
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider
            value={{ isAuthenticated, isLoading, login, logout }}
        >
            {children}
        </AuthContext.Provider>
    );
}
