import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { ToastComponent } from "./context/ToastContext";
import store from "./store";
import Auth from "./context/AuthContext";

if (import.meta.hot) {
  import.meta.hot.accept();
}

ReactDOM.render(
  <Provider store={store}>
    <Auth>
      <ToastComponent>
        <App />
      </ToastComponent>
    </Auth>
  </Provider>,
  document.getElementById("root"),
);
