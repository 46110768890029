import React, { useContext } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';
import { AuthContext } from '@context/AuthContext';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/auth/login'));

const App = () => {

    const Auth = useContext(AuthContext);

    return (
        <HashRouter>
            <React.Suspense fallback={loading}>
                <Switch>
                    { !Auth.isAuthenticated && <Route path="/login" render={props => <Login {...props} />} /> }
                    { !Auth.isLoading && !Auth.isAuthenticated && <Redirect to="/login" /> }
                    { Auth.isAuthenticated && <Route path="/" render={props => <TheLayout {...props} />} /> }
                    {/* { Auth.isAuthenticated && <Route path="/" element={<TheLayout />} /> } */}
                </Switch>
            </React.Suspense>
        </HashRouter>
    );
}

export default App;
